import { ApiError } from "openapi-typescript-fetch";
import { FilterParamObject } from "../components/WorkloadStatusByNamespace/useFilterQueryParams";
import {
  HAS_MOCKED_SCHEDULE_POLICY_CONFIG,
  MOCK_HPA_POLICIES_FETCHER,
  MOCK_VERSION_WITH_FREE_TRIAL,
} from "../utils/developmentFeatureFlags";
import { ScaleOps } from "./api";
import {
  createHPAPolicyMockData,
  deleteHPAPolicyMockData,
  getHPAPoliciesMockData,
  getMockedFreeTrialData,
  getMockedPolicySchedule,
  getMockGetNotEvictableGraphsResponse,
  updateFreeTrialMockData,
} from "./mockUtils";
import { components, operations, paths } from "./schema";

export type WithTimeout = {
  timeoutSeconds?: number;
};

// Get Dashboard By Namespace
export enum LogicalOperator {
  AND = "AND",
  OR = "OR",
}

export type GetDashboardByNamespaceResponse =
  paths["/api/v1/dashboard/byNamespace"]["get"]["responses"]["200"]["content"]["application/json"];

export const getDashboardByNamespace = () => {
  const api = ScaleOps();
  const queryKey = "getDashboardByNamespace";

  const queryFn = async (params: FilterParamObject & { multiCluster?: boolean } & WithTimeout) => {
    const { timeoutSeconds, ...queryParams } = params;
    const response = await api
      .getFetcher()
      .withTimeout(timeoutSeconds)
      .path("/api/v1/dashboard/byNamespace")
      .method("post")
      .create()({
        ...queryParams,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Network Cost Enabled
export type GetNetworkCostEnabledResponse = components["schemas"]["NetworkCostNetworkCostEnabledResponse"];

export type GetNetworkCostEnabledParams = {
  clusters?: string[];
  multiCluster?: boolean;
  tags?: string[];
};

export const GetNetworkCostEnabled = () => {
  const queryKey = "GetNetworkCostEnabled";
  const api = ScaleOps();
  const queryFn = async (params: GetNetworkCostEnabledParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/networkCost/networkCostEnabled")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Cost Report Workloads
export type GetCostReportWorkloadsResponse = components["schemas"]["UtilsAggregatedWorkloadCostReport"];

export type GetCostReportWorkloadsParams = {
  clusters?: string[];
  daysBack?: number;
  multiCluster?: boolean;
  tags?: string[];
  annotations?: string[];
  clusterFilters?: string[];
  isAnnotationExclude?: boolean;
  isClusterExclude?: boolean;
  isLabelExclude?: boolean;
  isNamespaceExclude?: boolean;
  isWorkloadTypeExclude?: boolean;
  labels?: string[];
  logicalAnnotation?: string;
  logicalLabel?: string;
  namespaces?: string[];
  shouldIgnoreWorkloads?: boolean;
  workloadTypes?: string[];
  gpuWorkloadsOnly?: boolean;
  type?: BreakdownCostTypes;
};

export const GetCostReportWorkloads = () => {
  const queryKey = "GetCostReportWorkloads";
  const api = ScaleOps();
  const queryFn = async (params: GetCostReportWorkloadsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/costReport/getWorkloads")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Cost Report Workloads
export type GetNetworkReportWorkloadsResponse = components["schemas"]["NetworkCostAggregatedWorkloads"];

export type GetNetworkReportWorkloadsParams = {
  clusters?: string[];
  daysBack?: number;
  multiCluster?: boolean;
  tags?: string[];
  annotations?: string[];
  clusterFilters?: string[];
  isAnnotationExclude?: boolean;
  isClusterExclude?: boolean;
  isLabelExclude?: boolean;
  isNamespaceExclude?: boolean;
  isWorkloadTypeExclude?: boolean;
  labels?: string[];
  logicalAnnotation?: string;
  logicalLabel?: string;
  namespaces?: string[];
  shouldIgnoreWorkloads?: boolean;
  workloadTypes?: string[];
};

export const GetNetworkReportWorkloads = () => {
  const queryKey = "GetNetworkReportWorkloads";
  const api = ScaleOps();
  const queryFn = async (params: GetNetworkReportWorkloadsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/networkCost/getWorkloads")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Cost Breakdown
export type GetCostBreakdownResponse = components["schemas"]["UtilsCostBreakdownResponse"];

export type BreakdownCostTypes = "cost" | "savingsAvailable" | "savingsAvailableWithReplicas";

export type GetCostBreakdownParams = {
  aggregation?: number;
  breakDownBy?: string;
  clusters?: string[];
  daysBack?: number;
  multiCluster?: boolean;
  tags?: string[];
  topk?: number;
  annotations?: string[];
  clusterFilters?: string[];
  isAnnotationExclude?: boolean;
  isClusterExclude?: boolean;
  isLabelExclude?: boolean;
  isNamespaceExclude?: boolean;
  isWorkloadTypeExclude?: boolean;
  labels?: string[];
  logicalAnnotation?: string;
  logicalLabel?: string;
  namespaces?: string[];
  shouldIgnoreWorkloads?: boolean;
  workloadTypes?: string[];
  type: BreakdownCostTypes;
  gpuWorkloadsOnly?: boolean;
};

export const GetCostBreakdown = () => {
  const queryKey = "GetCostBreakdown";
  const api = ScaleOps();
  const queryFn = async (params: GetCostBreakdownParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/costReport/getCostBreakdown")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Network Cost Breakdown
export type GetNetworkCostBreakdownResponse = components["schemas"]["UtilsNetworkCostBreakdownResponse"];

export type GetNetworkCostBreakdownParams = {
  aggregation?: number;
  breakDownBy?: string;
  clusters?: string[];
  from?: number | null;
  multiCluster?: boolean;
  range?: string | null;
  stepSize?: number | null;
  tags?: string[];
  to?: number | null;
  topk?: number;
  annotations?: string[];
  clusterFilters?: string[];
  isAnnotationExclude?: boolean;
  isClusterExclude?: boolean;
  isLabelExclude?: boolean;
  isNamespaceExclude?: boolean;
  isWorkloadTypeExclude?: boolean;
  labels?: string[];
  logicalAnnotation?: string;
  logicalLabel?: string;
  namespaces?: string[];
  shouldIgnoreWorkloads?: boolean;
  workloadTypes?: string[];
};

export const GetNetworkCostBreakdown = () => {
  const queryKey = "GetNetworkCostBreakdown";
  const api = ScaleOps();
  const queryFn = async (params: GetNetworkCostBreakdownParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/networkCost/getCostBreakdown")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Network Cost Breakdown Aggregations
export type GetNetworkCostBreakdownAggregationsResponse = components["schemas"]["UtilsNetworkCostBreakdownResponse"];

export type GetNetworkCostBreakdownAggregationsParams = {
  aggregation?: number;
  clusters?: string[];
  from?: number | null;
  groupByCluster?: boolean;
  groupByNamespace?: boolean;
  multiCluster?: boolean;
  range?: string | null;
  shouldIgnoreWorkloads?: boolean;
  stepSize?: number | null;
  tags?: string[];
  to?: number | null;
  topk?: number;
  groupByAnnotations?: string[];
  groupByLabels?: string[];
};

export const GetNetworkCostBreakdownAggregations = () => {
  const queryKey = "GetNetworkCostBreakdownAggregations";
  const api = ScaleOps();
  const queryFn = async (params: GetNetworkCostBreakdownAggregationsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/networkCost/getCostBreakdownAggregations")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get GPU Cost Breakdown
export type GetGpuCostBreakdownResponse = components["schemas"]["GpuGPUMetricsResponse"];

export type GetGpuCostBreakdownParams = {
  clusters?: string[];
  from?: number | null;
  to?: number | null;
  stepSizeMinutes?: number | null;
  multiCluster?: boolean;
};

export const GetGpuCostBreakdown = () => {
  const queryKey = "GetGpuCostBreakdown";
  const api = ScaleOps();
  const queryFn = async (params: GetGpuCostBreakdownParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/gpu/metrics")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get DCGM Exporter Info
export type GetDCGMExporterInfoResponse = components["schemas"]["GpuDCGMInfoResponse"];

export type GetDCGMExporterInfoParams = {
  clusters?: string[];
};

export const GetDCGMExporterInfo = () => {
  const queryKey = "GetDCGMExporterInfo";
  const api = ScaleOps();
  const queryFn = async (params: GetDCGMExporterInfoParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/gpu/dcgm-exporter-info")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Cluster GPU Info
export type GetClusterGPUInfoResponse = components["schemas"]["GpuClusterGPUInfoResponse"];

export type GetClusterGPUInfoParams = {
  clusters?: string[];
  multiCluster?: boolean;
};

export const GetClusterGPUInfo = () => {
  const queryKey = "GetClusterGPUInfo";
  const api = ScaleOps();
  const queryFn = async (params: GetClusterGPUInfoParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/gpu/gpu-cluster-info")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get GPU Nodes Info
export type GetGPUNodesInfoResponse = components["schemas"]["GpuGPUNodesInfoResponse"];

export type GetGPUNodesInfoParams = {
  from?: number | null;
  to?: number | null;
  stepSizeMinutes?: number | null;
  clusters?: string[];
  multiCluster?: boolean;
};

export const GetGPUNodesInfo = () => {
  const queryKey = "GetGPUNodesInfo";
  const api = ScaleOps();
  const queryFn = async (params: GetGPUNodesInfoParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/gpu/gpu-nodes-info")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get all capped statuses
export type GetCappedStatusesResponse = components["schemas"]["Capped_statusesGetCappedStatusesResponse"];

export type GetCappedStatusesParams = {
  clusters?: string[];
  multiCluster?: boolean;
};

export const GetCappedStatuses = () => {
  const queryKey = "GetCappedStatuses";
  const api = ScaleOps();
  const queryFn = async (params: GetCappedStatusesParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/capped-statuses/")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get customer token
export type GetCustomerTokenResponse = components["schemas"]["VersionGetCustomerTokenResponse"];

export const GetCustomerToken = () => {
  const queryKey = "GetCustomerToken";
  const api = ScaleOps();
  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/customer-token")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Workload Events Graph
export type GetWorkloadEventsGraphResponse = components["schemas"]["WorkloadsEventGraph"];

export type GetWorkloadEventsGraphParams = {
  kind: string;
  name: string;
  namespace: string;
  from?: number | null;
  range?: string | null;
  stepSize?: number | null;
  to?: number | null;
};

export const GetWorkloadEventsGraph = () => {
  const queryKey = "GetWorkloadEventsGraph";
  const api = ScaleOps();
  const queryFn = async (params: GetWorkloadEventsGraphParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/workload/{namespace}/{kind}/{name}/events")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get workload network
export type GetWorkloadNetworkResponse = components["schemas"]["WorkloadsWorkloadNetworkResponse"];

export type GetWorkloadNetworkParams = {
  from?: number | null;
  name?: string;
  namespace?: string;
  range?: string | null;
  stepSize?: number | null;
  to?: number | null;
  topk?: number;
  workloadType?: string;
};

export const GetWorkloadNetwork = () => {
  const queryKey = "GetWorkloadNetwork";
  const api = ScaleOps();
  const queryFn = async (params: GetWorkloadNetworkParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/workload-network")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Cost Breakdown Aggregations
export type GetCostBreakdownAggregationsResponse = components["schemas"]["UtilsCostBreakdownResponse"];

export type GetCostBreakdownAggregationsParams = {
  aggregation?: number;
  clusters?: string[];
  daysBack?: number;
  groupByCluster?: boolean;
  groupByNamespace?: boolean;
  multiCluster?: boolean;
  shouldIgnoreWorkloads?: boolean;
  tags?: string[];
  topk?: number;
  groupByAnnotations?: string[];
  groupByLabels?: string[];
  type: BreakdownCostTypes;
  gpuWorkloadsOnly?: boolean;
};

export const GetCostBreakdownAggregations = () => {
  const queryKey = "GetCostBreakdownAggregations";
  const api = ScaleOps();
  const queryFn = async (params: GetCostBreakdownAggregationsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/costReport/getCostBreakdownAggregations")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Aggregations
export type GetAggregationsResponse = components["schemas"]["UtilsAggregatedWorkloadCostReport"];

export type GetAggregationsParams = {
  clusters?: string[];
  daysBack?: number;
  groupByNamespace?: false | false;
  multiCluster?: boolean;
  tags?: string[];
  groupByAnnotations?: string[];
  groupByLabels?: string[];
  gpuWorkloadsOnly?: boolean;
};

export const GetAggregations = () => {
  const queryKey = "GetAggregations";
  const api = ScaleOps();
  const queryFn = async (params: GetAggregationsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/costReport/getAggregations")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Network Aggregations
export type GetNetworkAggregationsResponse = components["schemas"]["NetworkCostAggregatedWorkloads"];

export type GetNetworkAggregationsParams = {
  clusters?: string[];
  from?: number | null;
  groupByCluster?: boolean;
  groupByNamespace?: boolean;
  multiCluster?: boolean;
  range?: string | null;
  shouldIgnoreWorkloads?: boolean;
  stepSize?: number | null;
  tags?: string[];
  to?: number | null;
  groupByAnnotations?: string[];
  groupByLabels?: string[];
};

export const GetNetworkAggregations = () => {
  const queryKey = "GetNetworkAggregations";
  const api = ScaleOps();
  const queryFn = async (params: GetAggregationsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/networkCost/getAggregations")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Workloads From Aggregation
export type GetWorkloadsFromAggregationResponse = components["schemas"]["UtilsAggregatedWorkloadCostReport"];

export type GetWorkloadsFromAggregationParams = {
  clusters?: string[];
  daysBack?: number;
  groupByCluster?: boolean;
  groupByNamespace?: boolean;
  multiCluster?: boolean;
  shouldIgnoreWorkloads?: boolean;
  tags?: string[];
  groupByAnnotations?: string[];
  groupByLabels?: string[];
};

export const GetWorkloadsFromAggregation = () => {
  const queryKey = "GetWorkloadsFromAggregation";
  const api = ScaleOps();
  const queryFn = async (params: GetAggregationsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/costReport/getWorkloadsFromAggregation")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Workload Containers
export type GetWorkloadContainersResponse = components["schemas"]["WorkloadsGetWorkloadContainersResponse"];

export type GetWorkloadContainersParams = {
  name?: string;
  namespace?: string;
  workloadType?: string;
};

export const GetWorkloadContainers = () => {
  const queryKey = "GetWorkloadContainers";
  const api = ScaleOps();
  const queryFn = async (params: GetWorkloadContainersParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/workload-containers")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Audits
export type GetPersistentClusterEventsParams = {
  range: string | undefined;
  from: number | undefined;
  to: number | undefined;
  namespaces: (string | null)[];
  labels: (string | null)[];
  annotations: (string | null)[];
  workloadTypes: (string | null)[];
  isNamespaceExclude: boolean | null;
  isLabelExclude: boolean | null;
  isAnnotationExclude: boolean | null;
  isWorkloadTypeExclude: boolean | null;
  logicalLabel: string| null;
  logicalAnnotation: string | null;
};

export type GetClusterPersistentEventsResponse = components["schemas"]["WorkloadsEventGraph"];

export const GetClusterPersistentEvents = () => {
    const queryKey = "GetClusterPersistentEvents";
    const api = ScaleOps();

    const queryFn = async (params: GetPersistentClusterEventsParams) => {
      const filteredParams = {
        ...params,
        namespaces: params.namespaces.filter((item): item is string => item !== null),
        labels: params.labels.filter((item): item is string => item !== null),
        annotations: params.annotations.filter((item): item is string => item !== null),
        workloadTypes: params.workloadTypes.filter((item): item is string => item !== null),
        isNamespaceExclude: params.isNamespaceExclude ?? false,
        isLabelExclude: params.isLabelExclude ?? false,
        isAnnotationExclude: params.isAnnotationExclude ?? false,
        isWorkloadTypeExclude: params.isWorkloadTypeExclude ?? false,
        logicalLabel: params.logicalLabel ?? "OR",
        logicalAnnotation: params.logicalAnnotation ?? "OR",
      };
  const response = await api
        .getFetcher()
        .path("/api/v1/events/")
        .method("post")
        .create()(filteredParams)
        .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
}

// Get Audits
export type GetAuditsParams = {
  from?: number | null;
  range?: string | null;
  stepSize?: number | null;
  to?: number | null;
};

export type GetAuditsResponse = components["schemas"]["AuditsAuditEventsResponse"];

export const GetAudits = () => {
  const queryKey = "GetAudits";
  const api = ScaleOps();

  const queryFn = async (params: GetAuditsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/audits/")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Aggregated Overview
export type GetAggregatedOverviewResponse = components["schemas"]["DashGetAggregatedOverviewResponse"];

export type GetAggregatedOverviewProps = components["schemas"]["GetAggregatedOverviewInput"] & {
  scaleOpsProduct?: "rightsize" | "HPA" | "all";
};

export const GetAggregatedOverview = () => {
  const queryKey = "GetDashboard";
  const api = ScaleOps();
  const queryFn = async (params: GetAggregatedOverviewProps) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/dashboard/aggregatedOverview")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type GetAggregationWorkloadsResponse = components["schemas"]["DashGetAggregationWorkloadsResponse"];
export const GetAggregationWorkloads = () => {
  const queryKey = "GetAggregationWorkloads";
  const api = ScaleOps();
  const queryFn = async (params: GetAggregatedOverviewProps) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/dashboard/aggregationWorkloads")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Resource Quota Exists
export type GetResourceQuotaExistsResponse = components["schemas"]["AnalyticsGetResourceQuotaExistsResponse"];

export const GetResourceQuotaExists = () => {
  const api = ScaleOps();
  const queryKey = "GetResourceQuotaExists";

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/analytics/resourcequota/exists")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };

  return { queryFn, queryKey };
};

// Get Rbac
export type GetRbacResponse = components["schemas"]["RbacGetRolesResponse"];

export const GetRbac = () => {
  const api = ScaleOps();
  const queryKey = "GetRbac";

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/auth/rbac")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Post Rbac
export type PostRbacParam = components["schemas"]["CreateRuleApi-FmInput"];

export const PostRbac = () => {
  const api = ScaleOps();
  const queryKey = "PostRbac";

  const queryFn = async (params: PostRbacParam) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/auth/rbac")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Update Rbac
export type PatchRbacParam = components["schemas"]["PatchRuleApi-FmInput"] & {
  id: string;
};

export const PatchRbac = () => {
  const api = ScaleOps();
  const queryKey = "UpdateRbac";

  const queryFn = async (params: PatchRbacParam) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/auth/rbac/{id}")
      .method("patch")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Delete Rbac
export type DeleteRbacParam = {
  id: string;
};

export const DeleteRbac = () => {
  const api = ScaleOps();
  const queryKey = "DeleteRbac";

  const queryFn = async (params: PatchRbacParam) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/auth/rbac/{id}")
      .method("delete")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// rebalance post
export type RebalanceOnceResponse = components["schemas"]["NodesRebalanceOnceResponse"];

export type RebalanceOnceParams = components["schemas"]["RebalanceOnceInput"];

export const RebalanceOnce = () => {
  const api = ScaleOps();
  const queryKey = "rebalanceOnce";

  const queryFn = async (params: RebalanceOnceParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodes/rebalance-once")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};
// Get Not Evictable Graphs
export type GetNotEvictableGraphsResponse = components["schemas"]["NodeGroupsGetNotEvictableGraphsResponse"];

export type GetNotEvictableGraphsQueryKey =
  | "waste"
  | "numberBlockedNodes"
  | "optimizedUnevictablePods"
  | "totalUnevictablePods"
  | "blockedAllocatableCpu"
  | "blockedAllocatableMemory";

export type GetNotEvictableGraphsParams = {
  clusters?: string[];
  queryKey?: GetNotEvictableGraphsQueryKey[];
  from?: number | null;
  to?: number | null;
  range?: string | null;
  stepSize?: number | null;
  tags?: string[];
  multiCluster?: boolean;
};

export const GetNotEvictableGraphs = () => {
  const isMockedRoute = false;
  const api = ScaleOps();
  const queryKey = "GetNotEvictableGraphs";

  const queryFn = async (params: GetNotEvictableGraphsParams) => {
    if (isMockedRoute) {
      const mockOutput = getMockGetNotEvictableGraphsResponse(params.from, params.to, params.queryKey ?? ["example"]);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return mockOutput;
    }

    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/not-evictable/graphs")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// rebalance spec post
export type RebalanceSpec = components["schemas"]["NodesGetRebalanceStatusResponse"]["spec"];
export type SetRebalanceSpecResponse = components["schemas"]["NodesSetRebalanceSpecResponse"];
export const SetRebalanceSpec = () => {
  const api = ScaleOps();
  const queryKey = "setRebalanceSpec";

  const queryFn = async (spec: RebalanceSpec) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodes/rebalance-spec")
      .method("post")
      .create()({
        ...spec,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Rebalance Status
export type GetRebalanceStatusResponse = components["schemas"]["NodesGetRebalanceStatusResponse"];

export type SelectedReason = "UnevictableWorkloadDueToPdb" | "UnevictablePodWithoutOwner" | "PodWithLocalStorage" | "UnevictableWorkloadDueToAnnotation" | "UnevictableKubeSystemWorkloads" | "MinReplicasReached" | "PodOwnerReachedToMinimumReplicas" | "InsufficientAllocationForDynamicResources" | "UnsatisfiedPvcRequirements" | "UnsatisfiedPvcVolumeRestrictions" | "UnsatisfiedPvcZoneRequirements" | "PodAffinityConstraint" | "PodAnti-affinityConstraint" | "NodeAffinityConstraint" | "NodeSelectorConstraint" | "UnsatisfiedNodeName" | "UnsatisfiedAvailablePort" | "InsufficientAllocationForResourceRequests" | "NodeWithAnUnschedulableTaint" | "TopologySpreadConstraints" | "NodeWithAnUntoleratedTaint" | "MissingKarpenterNodeLabels"  | "InvalidNodePool" | "KarpenterDisruptionBlocked" | "KarpenterNodeAnnotationConstraint" | "MissingProvisioner" | "ProvisionerConsolidationDisabled" | "EmptinessTimestampMissing" | "UnexpectedEmptinessStructure" | "NodePoolRequiredEmptinessDuration" | "WhenEmptyNodePoolConfiguration" | "MissingNodePool" | "NodePoolConsolidationDisabled" | "NodePoolBudgetConstraint" | "NodeOverUtilized" | "LowClusterAutoscalerScalingDownAllocationThreshold" | "ClusterAutoScalerAnnotationConstraint" | "ReachedMinimumNodeGroupSize" | "NotUnreadyLongEnough" | "DeletionInProgress" | "NodePendingRemoval" | "UninitializedNode" | "NoReason" | "UnexpectedError" | "ScalerLabelConstraint" | "ScalerAnnotationConstraint" | "NodeHighAllocation" | "HighUtilization" | "NotAutoscaled" | "RecentlyUnremovable" | "NotUnneededLongEnough" | "MinimalResourceLimitExceeded" | "NodeWasInitiatedRecently" | "NoPlaceToMovePods" | "BlockedByPod" | "NotSafeToEvictLabel";

export type GetRebalanceStatusParams = {
  nodeConsolidationFilter?: components["schemas"]["NodeGroupsNodeFilters"];
};

export const GetRebalanceStatus = () => {
  const api = ScaleOps();
  const queryKey = "GetRebalanceStatus";

  const queryFn = async (params: GetRebalanceStatusParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodes/rebalance-status")
      .method("post")
      .create()({
        nodeConsolidationFilter: params.nodeConsolidationFilter,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Policies
export type GetPoliciesResponse = paths["/api/v1/policies"]["get"]["responses"]["200"]["content"]["application/json"];

export const getPolicies = () => {
  const api = ScaleOps();
  const queryKey = "getPolicies";

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/policies")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }

    if (HAS_MOCKED_SCHEDULE_POLICY_CONFIG) {
      return {
        ...response,
        policies: [...(response?.policies ?? []), ...getMockedPolicySchedule()],
      };
    }
    return response;
  };

  return { queryFn, queryKey };
};

export const getAnalytics = () => {
  const api = ScaleOps();
  const queryKey = "getAnalytics";
  const queryFn = async ({
    queries,
    since = "4 Hours",
    fromTimestamp = undefined,
  }: components["schemas"]["GetAnalyticsInput"]) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/analytics/")
      .method("post")
      .create()({ queries, since, fromTimestamp })
      .then((response) => response.data);
    if (response instanceof Error) {
      throw response;
    }
    return response;
  };
  return { queryFn, queryKey };
};

// Get Workload Recommendation
export type GetWorkloadRecommendationResponse =
  paths["/api/v1/recommendation/{namespace}/{name}"]["get"]["responses"]["200"]["content"]["application/json"];

export const getWorkloadRecommendation = () => {
  const api = ScaleOps();
  const queryKey = "getWorkloadRecommendation";

  const queryFn = async ({ namespace, name }: { namespace: string; name: string }) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/recommendation/{namespace}/{name}")
      .method("get")
      .create()({
        namespace,
        name,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// update Automation

export type UpdateAutomationParams = {
  namespace: string;
  workloadName: string;
  workloadType: string;
  scaleOpsProduct?: "rightsize" | "HPA" | "all";
};

export type UpdateAutomationResponse =
  paths["/api/v1/auto/{namespace}/{workloadType}/{workloadName}"]["post"]["responses"]["200"]["content"]["application/json"];

export const updateAutomation = () => {
  const api = ScaleOps();
  const queryKey = "updateAutomation";

  const queryFn = async (params: UpdateAutomationParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/auto/{namespace}/{workloadType}/{workloadName}")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Save Policy
// /api/v1/policy/
export type SavePolicyResponse = paths["/api/v1/policy/"]["post"]["responses"]["200"]["content"]["application/json"];

export const savePolicy = () => {
  const api = ScaleOps();
  const queryKey = "savePolicy";

  const queryFn = async ({ policy }: { policy: components["schemas"]["V1alpha1Policy"] }) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/policy/")
      .method("post")
      .create()({
        policy,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Update Policy
export type UpdatePolicyResponse =
  paths["/api/v1/policy/{name}"]["put"]["responses"]["200"]["content"]["application/json"];

export const updatePolicy = () => {
  const api = ScaleOps();
  const queryKey = "updatePolicy";

  const queryFn = async ({
    policyName,
    policy,
  }: {
    policyName: string;
    policy: components["schemas"]["V1alpha1Policy"];
  }) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/policy/{name}")
      .method("put")
      .create()({
        policy,
        name: policyName,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Update Policy By Namespace
export type UpdatePolicyByNamespaceParams = {
  name: string; // policyName,
  target: string; // namespace,
};

export const UpdatePolicyByNamespace = () => {
  const api = ScaleOps();
  const queryKey = "UpdatePolicyByNamespace";

  const queryFn = async (params: UpdatePolicyByNamespaceParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/policy/{name}/namespace/attach")
      .method("patch")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type UpdateNamespacesInBulkParams = components["schemas"]["AutoNamespacesBulkInput"];

export const UpdateNamespacesInBulk = () => {
  const api = ScaleOps();
  const queryKey = "UpdateNamespacesInBulk";

  const queryFn = async (params: UpdateNamespacesInBulkParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/auto/namespaces")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// update Automation Exclude
export type UpdateAutomationExclude =
  paths["/api/v1/recommendation/{namespace}/{name}/setAutomationExclude"]["patch"]["responses"]["200"]["content"]["application/json"];

export const updateAutomationExclude = () => {
  const api = ScaleOps();
  const queryKey = "updateAutomationExclude";

  const queryFn = async ({ namespace, name, isExclude }: { namespace: string; name: string; isExclude: boolean }) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/recommendation/{namespace}/{name}/setAutomationExclude")
      .method("patch")
      .create()({
        namespace,
        name,
        isExclude,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Update Namespace Automation

export type UpdateNamespaceAutomationParams = {
  namespace: string;
  state: boolean;
  idsToSkip?: string[];
};

export const updateNamespaceAutomation = () => {
  const api = ScaleOps();
  const queryKey = "updateNameSpaceAutomation";

  const queryFn = async ({ namespace, state }: UpdateNamespaceAutomationParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/auto/{namespace}")
      .method("post")
      .create()({
        namespace,
        state,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Delete Policy
export type DeletePolicyResponse =
  paths["/api/v1/policy/{name}"]["delete"]["responses"]["200"]["content"]["application/json"];

export const deletePolicy = () => {
  const api = ScaleOps();
  const queryKey = "deletePolicy";

  const queryFn = async ({ policyName }: { policyName: string }) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/policy/{name}")
      .method("delete")
      .create()({
        name: policyName,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Node Group Info
export type GetNodeGroupInfoResponse =
  paths["/api/v1/nodegroups/"]["post"]["responses"]["200"]["content"]["application/json"];

export type GetNodeGroupInfoInput = components["schemas"]["NodeGroupsNodeFilters"];
export type ScaleDownReasons = "UnevictableWorkloadDueToPdb" | "UnevictablePodWithoutOwner" | "PodWithLocalStorage" | "UnevictableWorkloadDueToAnnotation" | "UnevictableKubeSystemWorkloads" | "MinReplicasReached" | "PodOwnerReachedToMinimumReplicas" | "InsufficientAllocationForDynamicResources" | "UnsatisfiedPvcRequirements" | "UnsatisfiedPvcVolumeRestrictions" | "UnsatisfiedPvcZoneRequirements" | "PodAffinityConstraint" | "PodAnti-affinityConstraint" | "NodeAffinityConstraint" | "NodeSelectorConstraint" | "UnsatisfiedNodeName" | "UnsatisfiedAvailablePort" | "InsufficientAllocationForResourceRequests" | "NodeWithAnUnschedulableTaint" | "TopologySpreadConstraints" | "NodeWithAnUntoleratedTaint" | "MissingKarpenterNodeLabels"  | "InvalidNodePool" | "KarpenterDisruptionBlocked" | "KarpenterNodeAnnotationConstraint" | "MissingProvisioner" | "ProvisionerConsolidationDisabled" | "EmptinessTimestampMissing" | "UnexpectedEmptinessStructure" | "NodePoolRequiredEmptinessDuration" | "WhenEmptyNodePoolConfiguration" | "MissingNodePool" | "NodePoolConsolidationDisabled" | "NodePoolBudgetConstraint" | "NodeOverUtilized" | "LowClusterAutoscalerScalingDownAllocationThreshold" | "ClusterAutoScalerAnnotationConstraint" | "ReachedMinimumNodeGroupSize" | "NotUnreadyLongEnough" | "DeletionInProgress" | "NodePendingRemoval" | "UninitializedNode" | "NoReason" | "UnexpectedError" | "ScalerLabelConstraint" | "ScalerAnnotationConstraint" | "NodeHighAllocation" | "HighUtilization" | "NotAutoscaled" | "RecentlyUnremovable" | "NotUnneededLongEnough" | "MinimalResourceLimitExceeded" | "NodeWasInitiatedRecently" | "NoPlaceToMovePods" | "BlockedByPod" | "NotSafeToEvictLabel"

export type NodeGroupsScaleDownReason = {
  reason: ScaleDownReasons;
  count?: number;
};

export const GetNodeGroupInfo = () => {
  const queryKey = "getNodeGroupInfo";
  const api = ScaleOps();
  const queryFn = async (filters: GetNodeGroupInfoInput) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/")
      .method("post")
      .create()({ filters: filters })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type GetNodeFilterValuesResponse =
  paths["/api/v1/nodegroups/known-filter-values"]["get"]["responses"]["200"]["content"]["application/json"];

export const GetNodeFilterValues = () => {
  const queryKey = "getNodeFilterValues";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/known-filter-values")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Version
export type GetVersionResponse = components["schemas"]["VersionGetIsLatestScaleopsVersionResponse"];

export const GetVersion = () => {
  const queryKey = "GetVersion";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/version")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }

    if (MOCK_VERSION_WITH_FREE_TRIAL) {
      return {
        ...response,
        freeTrial: getMockedFreeTrialData(),
      };
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Version with customCluster
export type GetVersionWithCustomClusterResponse = components["schemas"]["VersionGetIsLatestScaleopsVersionResponse"];

export type GetVersionWithCustomClusterParams = {
  customCluster?: string;
};

export const GetVersionWithCustomCluster = () => {
  const queryKey = "GetVersion";
  const api = ScaleOps();

  const queryFn = async (params: GetVersionWithCustomClusterParams) => {
    const response = await api
      .getFetcher(false, params.customCluster)
      .path("/api/v1/version")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }

    if (MOCK_VERSION_WITH_FREE_TRIAL) {
      return {
        ...response,
        freeTrial: getMockedFreeTrialData(),
      };
    }
    return response;
  };

  return { queryFn, queryKey };
};

// ask for premium
export type AskForPremiumResponse = "/api/v1/version/ask-for-premium";

export type AskForPremiumParams = {
  customCluster?: string;
};

export const AskForPremium = () => {
  const queryKey = "AskForPremium";
  const api = ScaleOps();

  const queryFn = async (params: AskForPremiumParams) => {
    if (MOCK_VERSION_WITH_FREE_TRIAL) {
      updateFreeTrialMockData({
        ...getMockedFreeTrialData(),
        hasAskedForPremium: true,
      });
      return getMockedFreeTrialData();
    }

    const response = await api
      .getFetcher(false, params.customCluster)
      .path("/api/v1/version/ask-for-premium")
      .method("post")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };

  return { queryFn, queryKey };
};

// Update Alerts Settings
export type UpdateAlertsSettingsResponse = components["schemas"]["AlertsUpdateAlertConfigResponse"];

export const UpdateAlertsSettings = () => {
  const queryKey = "UpdateAlertsSettings";
  const api = ScaleOps();

  const queryFn = async (params: components["schemas"]["UpdateAlertSettingsInput"]) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/alerts/settings")
      .method("put")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};
// Get Policy Tuning Diagnostics
export type GetPolicyTuningDiagnosticsResponse = components["schemas"]["PolicyTuningGetDiagnosticDataResponse"];

export type GetPolicyTuningDiagnosticsParams = {
  container?: string | null;
  endDate?: string;
  period?: string;
  startDate?: string;
  name: string;
  namespace: string;
};

export const GetPolicyTuningDiagnostics = () => {
  const queryKey = "GetPolicyTuningDiagnostics";
  const api = ScaleOps();
  const queryFn = async (params: GetPolicyTuningDiagnosticsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/recommendation/{namespace}/{name}/policyTuning/diagnostics")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Node Diagnostics Events
export type GetNodeDiagnosticsResponse = components["schemas"]["NodeGroupsGetDiagnosticDataResponse"];

export type GetNodeDiagnosticsResponseParam = {
  from?: number;
  to?: number;
  name: string;
};

export const GetNodeDiagnostics = () => {
  const queryKey = "GetNodeDiagnosticsResponse";
  const api = ScaleOps();
  const queryFn = async (params: GetNodeDiagnosticsResponseParam) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/{name}/diagnostics")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Node Groups Waste

export type NodeGroupsGetWasteResponse = components["schemas"]["NodeGroupsGetWasteResponse"];

export const GetNodeGroupsWaste = () => {
  const queryKey = "GetNodeGroupsWaste";
  const api = ScaleOps();
  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/waste")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};
// Get Alerts Settings
export type AlertsSettingsResponse = components["schemas"]["AlertsGetAlertsConfigResponse"];

export const GetAlertsSettings = () => {
  const queryKey = "getAlertsSettings";
  const api = ScaleOps();
  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/alerts/settings")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Policy Tuning Config
export type GetPolicyTuningConfigParamsResponse =
  paths["/api/v1/recommendation/{namespace}/{name}/policyTuning/ConfigParams"]["get"]["responses"]["200"]["content"]["application/json"];

export const GetPolicyTuningConfigParams = () => {
  const queryKey = "getPolicyTuningConfigParams";
  const api = ScaleOps();
  const queryFn = async ({
    namespace,
    name,
    policyName,
    container,
  }: {
    namespace: string;
    name: string;
    policyName: string;
    container?: string;
  }) => {
    // add policyName to the query params
    const response = await api
      .getFetcher()
      .path("/api/v1/recommendation/{namespace}/{name}/policyTuning/ConfigParams")
      .method("get")
      .create()({
        policyName,
        namespace,
        name,
        container,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// post auto cluster
export type PostAutoClusterParams = components["schemas"]["AutoClusterInput"];

export type PostAutoClusterResponse = components["schemas"]["UtilsAutoNamespacesBulkResponse"];

export const PostAutoCluster = () => {
  const queryKey = "postAutoCluster";
  const api = ScaleOps();
  const queryFn = async (params: PostAutoClusterParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/auto/cluster")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Dashboard Time Series (Current & Optimized cost)

export type DashboardTimeSeriesParams = {
  startTime: number | undefined;
  multiCluster?: boolean;
  filters: FilterParamObject;
  scaleOpsProduct?: "rightsize" | "HPA" | "all";
};

export type GetDashboardTimeSeriesResponse =
  paths["/api/v1/dashboard/timeseries"]["post"]["responses"]["200"]["content"]["application/json"];

export const getDashboardTimeSeries = () => {
  const queryKey = "getDashboardTimeSeries";
  const api = ScaleOps();
  const queryFn = async (params: DashboardTimeSeriesParams & WithTimeout) => {
    const { timeoutSeconds, ...queryParams } = params;
    const input = {
      startTime: queryParams.startTime,
      multiCluster: queryParams.multiCluster,
      ...queryParams.filters,
      scaleOpsProduct: queryParams.scaleOpsProduct,
    };
    // add policyName to the query params
    const response = await api
      .getFetcher()
      .withTimeout(timeoutSeconds)
      .path("/api/v1/dashboard/timeseries")
      .method("post")
      .create()(input)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Resources Over Time

export type ResourcesOverTimeParams = {
  aggregation?: string;
  annotation?: string[];
  automated?: boolean | null;
  clusters?: string[];
  from?: number | null;
  graphAggregation?: string;
  includeIgnoredNamespaces?: boolean | null;
  isAnnotationExclude?: boolean | null;
  isLabelExclude?: boolean | null;
  isNamespaceExclude?: boolean | null;
  isOptimizationGapsExclude?: boolean | null;
  isHpaPredictableExclude?: boolean | null;
  isPolicyExclude?: boolean | null;
  isPriorityClassExclude?: boolean | null;
  isTypeExclude?: boolean | null;
  isUpdateModeExclude?: boolean | null;
  isWastedResourcesExclude?: boolean | null;
  isWorkloadErrorsExclude?: boolean | null;
  label?: string[];
  logicalAnnotation?: "AND" | "and" | "And" | "OR" | "or" | "Or";
  logicalLabel?: "AND" | "and" | "And" | "OR" | "or" | "Or";
  logicalNamespace?: "AND" | "and" | "And" | "OR" | "or" | "Or";
  logicalOptimizationGaps?: "AND" | "and" | "And" | "OR" | "or" | "Or";
  logicalHpaPredictable?: "AND" | "and" | "And" | "OR" | "or" | "Or";
  logicalPolicy?: "AND" | "and" | "And" | "OR" | "or" | "Or";
  logicalPriorityClass?: "AND" | "and" | "And" | "OR" | "or" | "Or";
  logicalType?: "AND" | "and" | "And" | "OR" | "or" | "Or";
  logicalUpdateMode?: "AND" | "and" | "And" | "OR" | "or" | "Or";
  logicalWastedResources?: "AND" | "and" | "And" | "OR" | "or" | "Or";
  logicalWorkloadErrors?: "AND" | "and" | "And" | "OR" | "or" | "Or";
  multiCluster?: boolean;
  namespace?: string[];
  namespaces?: string | null;
  oom?: boolean | null;
  optimizationGaps?: string[];
  hpaPredictable?: string[];
  overProvisioned?: boolean | null;
  overProvisionedCpu?: boolean | null;
  overProvisionedMemory?: boolean | null;
  overProvisionedV2?: "cpu" | "memory" | "cpuAndMemory" | "cpuOrMemory";
  overProvisionedWRTOrigin?: "cpu" | "memory" | "cpuAndMemory" | "cpuOrMemory";
  pendingConsumers?: boolean | null;
  policy?: string[];
  priorityClass?: string[];
  savings?: boolean | null;
  showInactive?: boolean;
  stepSize?: number | null;
  tags?: string[];
  to?: number | null;
  type?: string[];
  unAutomated?: boolean | null;
  unEvictable?: boolean | null;
  underProvisioned?: boolean | null;
  underProvisionedCpu?: boolean | null;
  underProvisionedMemory?: boolean | null;
  underProvisionedV2?: "cpu" | "memory" | "cpuAndMemory" | "cpuOrMemory";
  underProvisionedWRTOrigin?: "cpu" | "memory" | "cpuAndMemory" | "cpuOrMemory";
  updateMode?: ("Ongoing" | "OnCreate" | "Inplace")[];
  wastedResources?: (
    | "WorkloadErrorImagePullBackOff"
    | "WorkloadErrorStuckTerminating"
    | "WorkloadErrorPodInitializing"
    | "WorkloadErrorOther"
  )[];
  window?: string;
  workloadErrors?: ("WorkloadErrorDoubleSelected" | "WorkloadErrorFailedCreate" | "WorkloadErrorResourceQuota")[];
  withNoCluster?: boolean;
  scaleOpsProduct?: "rightsize" | "HPA" | "all";
};

export type ResourcesOverTimeResponse =
  paths["/api/v1/resourcesOverTime/"]["post"]["responses"]["200"]["content"]["application/json"];

export const getResourcesOverTime = () => {
  const queryKey = "getResourcesOverTime";
  const api = ScaleOps();
  const queryFn = async (params: ResourcesOverTimeParams & WithTimeout) => {
    const { timeoutSeconds, withNoCluster, ...queryParams } = params;
    const response = await api
      .getFetcher(withNoCluster)
      .withTimeout(timeoutSeconds)
      .path("/api/v1/resourcesOverTime/")
      .method("post")
      .create()(queryParams)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

type nodeFilters = {
  nodeFilters: GetNodeGroupInfoInput;
};

export const getResourceOverTimeNodes = () => {
  const queryKey = "getResourcesOverTimeNodes";
  const api = ScaleOps();
  const queryFn = async (params: ResourcesOverTimeParams & nodeFilters & WithTimeout) => {
    const { timeoutSeconds, withNoCluster, nodeFilters, ...queryParams } = params;
    const response = await api
      .getFetcher(withNoCluster)
      .withTimeout(timeoutSeconds)
      .path("/api/v1/nodegroups/node-resource-graphs")
      .method("post")
      .create()({ nodeFilters: nodeFilters, ...queryParams })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Policy Tuning Graph Data
export type GetPolicyTuningGraphDataResponse =
  paths["/api/v1/recommendation/{namespace}/{name}/policyTuning/GraphData"]["get"]["responses"]["200"]["content"]["application/json"];

export type GetPolicyTuningGraphDataParams = {
  name: string;
  namespace: string;
  container?: string | null;
  cpuHeadroom?: number | null;
  cpuHistoryWindow?: string | null;
  cpuPercentile?: number | null;
  endDate?: string;
  memoryHeadroom?: number | null;
  memoryHistoryWindow?: string | null;
  memoryPercentile?: number | null;
  policyName?: string | null;
  startDate?: string;
};

export const getPolicyTuningGraphData = () => {
  const queryKey = "getPolicyTuningGraphData";
  const api = ScaleOps();

  const queryFn = async (params: GetPolicyTuningGraphDataParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/recommendation/{namespace}/{name}/policyTuning/GraphData")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Policy Tuning Hpa graph data
export type GetPolicyTuningHpaGraphDataResponse =
  paths["/api/v1/recommendation/{namespace}/{name}/workloadOverview/hpa/graphData"]["get"]["responses"]["200"]["content"]["application/json"];

export type GetPolicyTuningHpaGraphDataParams = {
  name: string;
  namespace: string;
  endDate?: string;
  policyName?: string | null;
  startDate?: string;
  historyWindow?: string | null;
  percentile?: number | null;
  predictionAheadOfTime?: string | null;
};

export const getPolicyTuningHpaGraphData = () => {
  const queryKey = "getPolicyTuningHpaGraphData";
  const api = ScaleOps();

  const queryFn = async (params: GetPolicyTuningHpaGraphDataParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/recommendation/{namespace}/{name}/workloadOverview/hpa/graphData")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Policy Tuning Hpa graph data
export type GetPolicyTuningHpaCurrentDataResponse =
  paths["/api/v1/recommendation/{namespace}/{name}/workloadOverview/hpa/currentData"]["get"]["responses"]["200"]["content"]["application/json"];

export type GetPolicyTuningHpaCurrentDataParams = {
  name: string;
  namespace: string;
};

export const getPolicyTuningHpaCurrentData = () => {
  const queryKey = "getPolicyTuningHpaCurrentData";
  const api = ScaleOps();

  const queryFn = async (params: GetPolicyTuningHpaCurrentDataParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/recommendation/{namespace}/{name}/workloadOverview/hpa/currentData")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Cluster Permissions GetClusterPermissions
export type GetClusterPermissionsResponse = components["schemas"]["AutoCanAutoClusterResponse"];

export const GetClusterPermissions = (): {
  queryFn: () => Promise<GetClusterPermissionsResponse>;
  queryKey: string;
} => {
  const queryKey = "GetClusterPermissions";
  const api = ScaleOps();
  const queryFn = async (): Promise<GetClusterPermissionsResponse> => {
    const response = await api
      .getFetcher()
      .path("/api/v1/auto/cluster/permissions")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Clusters
export type GetClustersResponse = components["schemas"]["MultiClusterListResponse"];
export const getClusters = (): {
  queryFn: () => Promise<GetClustersResponse>;
  queryKey: string;
} => {
  const queryKey = "getClusters";
  const api = ScaleOps();
  const queryFn = async (): Promise<GetClustersResponse> => {
    const response = await api
      .getFetcher()
      .path("/api/v1/clusters")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof ApiError) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Schedule Blockers Graph
export type GetScheduleBlockersGraphResponse = components["schemas"]["NodeGroupsNodeBlockersDataResponse"];

export const GetScheduleBlockersGraph = () => {
  const queryKey = "GetScheduleBlockersGraph";
  const api = ScaleOps();
  const queryFn = async (): Promise<GetScheduleBlockersGraphResponse> => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/schedule-blockers/")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof ApiError) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Multi Cluster settings
export type GetMultiClusterSettingsResponse =
  paths["/api/v1/clusters/settings"]["get"]["responses"]["200"]["content"]["application/json"];
export const getMultiClusterSettings = (): {
  queryFn: () => Promise<GetMultiClusterSettingsResponse>;
  queryKey: string;
} => {
  const queryKey = "getMultiClusterSettings";
  const api = ScaleOps();
  const queryFn = async (): Promise<GetMultiClusterSettingsResponse> => {
    const response = await api
      .getFetcher()
      .path("/api/v1/clusters/settings")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Namespaces
export type GetNamespacesResponse =
  paths["/api/v1/namespaces/"]["get"]["responses"]["200"]["content"]["application/json"];

export type GetNamespacesParam = {
  clusters?: string[];
  multiCluster?: boolean;
  tags?: string[];
};

export const getNamespaces = () => {
  const queryKey = "getNamespaces";
  const api = ScaleOps();

  const queryFn = async (params: GetNamespacesParam) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/namespaces/")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// get Labels v1
export type GetLabelsResponse = paths["/api/v1/labels/"]["get"]["responses"]["200"]["content"]["application/json"];

export const getLabels = () => {
  const queryKey = "getLabels";
  const api = ScaleOps();

  const queryFn = async (multiCluster?: boolean) => {
    const params = multiCluster ? { multiCluster: multiCluster } : {};
    const response = await api
      .getFetcher()
      .path("/api/v1/labels/")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// get Labels V2
export type GetLabelsResponseV2 = components["schemas"]["LabelsGetLabelsV2Response"];

export const getLabelsV2 = () => {
  const queryKey = "getLabelsV2";
  const api = ScaleOps();

  const queryFn = async (multiCluster?: boolean, ignoreNamespaces?: boolean) => {
    const params = multiCluster ? { multiCluster: multiCluster, ignoreNamespaces } : { ignoreNamespaces };
    const response = await api
      .getFetcher()
      .path("/api/v1/labels/v2")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// get PodLabelKeyValues

export type GetPodLabelKeyValuesResponse =
  paths["/api/v1/labels/"]["get"]["responses"]["200"]["content"]["application/json"];

export const getPodLabelKeyValues = () => {
  const queryKey = "getPodLabelKeyValues";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/labels/")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get LabelKeyValues

export type GetLabelKeyValuesResponse =
  paths["/api/v1/labels/workload"]["get"]["responses"]["200"]["content"]["application/json"];

export const getLabelKeyValues = () => {
  const queryKey = "getLabelKeyValues";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/labels/workload")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// get all namespaces labels
export type GetAllNamespacesLabelsResponse =
  paths["/api/v1/labels/allNamespacesLabels"]["get"]["responses"]["200"]["content"]["application/json"];

export const getAllNamespacesLabels = () => {
  const queryKey = "getallNamepsacesLabels";
  const api = ScaleOps();

  const queryFn = async (multiCluster?: boolean) => {
    const params = multiCluster ? { multiCluster: multiCluster } : {};
    const response = await api
      .getFetcher()
      .path("/api/v1/labels/allNamespacesLabels")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Policy Tuning Graph Data

export type GetWorkloadCostResponse =
  paths["/api/v1/cost/getWorkloadCost"]["get"]["responses"]["200"]["content"]["application/json"];

export const getWorkloadCost = () => {
  const queryKey = "getWorkloadCost";
  const api = ScaleOps();

  const queryFn = async ({
    cpuHeadroom,
    cpuPercentile,
    cpuWindow,
    memoryHeadroom,
    memoryPercentile,
    memoryWindow,
    namespace,
    recommendationName,
    workloadName,
    workloadType,
    container,
  }: {
    cpuHeadroom?: number;
    cpuPercentile?: number;
    cpuWindow?: string;
    memoryHeadroom?: number;
    memoryPercentile?: number;
    memoryWindow?: string;
    namespace?: string;
    recommendationName?: string;
    workloadName?: string;
    workloadType?: string;
    container?: string;
  }) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/cost/getWorkloadCost")
      .method("get")
      .create()({
        cpuHeadroom,
        cpuPercentile,
        cpuWindow,
        memoryHeadroom,
        memoryPercentile,
        memoryWindow,
        namespace,
        recommendationName,
        workloadName,
        workloadType,
        container,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Update Policy Tuning
export type UpdatePolicyTuning =
  paths["/api/v1/recommendation/{namespace}/{name}/policyTuning"]["patch"]["responses"]["200"]["content"]["application/json"];

export const updatePolicyTuning = () => {
  const queryKey = "updatePolicyTuning";
  const api = ScaleOps();

  const queryFn = async ({ name, namespace, policyName }: { name: string; namespace: string; policyName: string }) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/recommendation/{namespace}/{name}/policyTuning")
      .method("patch")
      .create()({
        name,
        namespace,
        policyName,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Node Group Info
export type GetNodeGroupRecommendationResponse =
  paths["/api/v1/nodegroups/configrecommendation"]["get"]["responses"]["200"]["content"]["application/json"];

export const GetNodeGroupRecommendation = () => {
  const queryKey = "GetNodeGroupRecommendation";
  const api = ScaleOps();
  const queryFn = async ({ assumeRightSized, applySpot }: { assumeRightSized: boolean; applySpot: boolean }) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/configrecommendation")
      .method("get")
      .create()({ assumeRightSized, applySpot })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Rest Workload Recommendation
export type ResetWorkloadRecommendationType =
  paths["/api/v1/recommendation/{namespace}/{name}/policyDefaults"]["patch"]["responses"]["200"]["content"]["application/json"];

export const resetWorkloadRecommendation = () => {
  const queryKey = "resetWorkloadRecommendationType";
  const api = ScaleOps();
  const queryFn = async ({ name, namespace }: { name: string; namespace: string }) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/recommendation/{namespace}/{name}/policyDefaults")
      .method("patch")
      .create()({
        name,
        namespace,
      })
      .then((response: { data: ResetWorkloadRecommendationType }) => response.data);
    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Current State Response
export type GetCurrentStateResponse = components["schemas"]["NodeGroupsCurrentStateResponse"];

export const GetCurrentState = () => {
  const queryKey = "GetCurrentState";
  const api = ScaleOps();
  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/currentState")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Node Group Info
export type GetNodeGroupShortInfoResponse =
  paths["/api/v1/nodegroups/short"]["get"]["responses"]["200"]["content"]["application/json"];

export const GetNodeGroupShortInfo = () => {
  const queryKey = "GetNodeGroupShortInfo";
  const api = ScaleOps();
  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/short")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Ca Configuration Deployment
export type GetCaConfigurationDeploymentResponse =
  components["schemas"]["NodeGroupsGetCaConfigurationDeploymentResponse"] & {
    ownerName?: string;
    podNamespace?: string;
  };

export type GetCaConfigurationInput = {
  name?: string;
  namespace?: string;
};

export const GetCaConfigurationDeployment = () => {
  const queryKey = "GetCaConfigurationDeployment";
  const api = ScaleOps();
  const queryFn = async (params: GetCaConfigurationInput) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/caConfigurationDeployment")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Ca Configuration
export type GetCaConfigurationResponse = components["schemas"]["NodeGroupsGetCaConfigurationResponse"];

export const GetCaConfiguration = () => {
  const queryKey = "GetCaConfiguration";
  const api = ScaleOps();
  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/caConfigurations")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Slack Conf
export type GetSlackConfResponse = components["schemas"]["SlackGetConfResponse"];

export const GetSlackConf = () => {
  const queryKey = "GetSlackConf";
  const api = ScaleOps();
  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/slack/conf")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Admission Controller
export type GetScaleopsPodsHealthResponse = components["schemas"]["HealthScaleOpsSystemHealthResponse"];

export const GetScaleopsPodsHealth = () => {
  const queryKey = "GetAdmissionController";
  const api = ScaleOps();
  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/health/")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Slack Channels
export type GetSlackChannelsResponse = components["schemas"]["AlertsGetSlackChannelsResponse"];

export type GetSlackChannelsQueryParams = {
  nextCursor?: string;
  token?: string;
};

export const GetSlackChannels = () => {
  const queryKey = "GetSlackChannels";
  const api = ScaleOps();
  const queryFn = async (params: GetSlackChannelsQueryParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/slack/channels")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Skarpenter Instances
export type SkarpenterInstancesResponse = components["schemas"]["SkarpenterGetInstancesResponse"];

export const GetSkarpenterInstances = () => {
  const queryKey = "GetSkarpenterInstances";
  const api = ScaleOps();
  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/skarpenter/instances")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Node Groups Node (/api/v1/nodegroups/node)

export type GetNodeGroupsNodesResponse = operations["GetNodeStats"]["responses"]["200"]["content"]["application/json"];
export type GetNodesPodsResponse = components["schemas"]["NodeGroupsNodeStatsResponse"]["pods"];
export type GetPodsResponse = components["schemas"]["UtilsPodInfo"];
export type PodTaint = components["schemas"]["V1Taint"];

export type GetNodeGroupsNodesParams = {
  from?: number | null;
  name?: string;
  stepSize?: number | null;
  to?: number | null;
};

export const GetNodeGroupsNodes = () => {
  const queryKey = "GetNodeGroupsNodes";
  const api = ScaleOps();
  const queryFn = async (params: GetNodeGroupsNodesParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/node")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Nodes And Cost
export type GetNodesAndCostResponse = components["schemas"]["SkarpenterTimeseriesResponse"];

export type GetNodesAndCostQueryParams = {
  from: number;
  to: number;
  stepSizeSeconds: number;
};

export const GetNodesAndCost = () => {
  const queryKey = "GetNodesAndCost";
  const api = ScaleOps();
  const queryFn = async (params: GetNodesAndCostQueryParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/skarpenter/nodes_and_cost")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Update Slack Conf
export type UpdateSlackConfResponse = components["schemas"]["SlackUpdateConfResponse"];

export const UpdateSlackConf = () => {
  const queryKey = "UpdateSlackConf";
  const api = ScaleOps();
  const queryFn = async (params: components["schemas"]["UpdateSlackConfigInput"]) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/slack/conf")
      .method("put")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Update Ca Configuration
export type UpdateCaConfiguration = components["schemas"]["NodeGroupsUpdateCaConfigurationResponse"];

export const updateCaConfiguration = () => {
  const queryKey = "updateCaConfiguration";
  const api = ScaleOps();

  const queryFn = async ({
    configurationKey,
    optimize,
    ownerName,
    podNamespace,
  }: components["schemas"]["UpdateCaConfigurationInput"]) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/caConfiguration")
      .method("put")
      .create()({
        configurationKey,
        optimize,
        ownerName,
        podNamespace,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Update Consolidation
export type UpdateConsolidation = components["schemas"]["NodeGroupsUpdateConsolidationResponse"];

export const updateConsolidation = () => {
  const queryKey = "updateConsolidation";
  const api = ScaleOps();

  const queryFn = async ({ provisionerName, optimize }: components["schemas"]["UpdateConsolidationInput"]) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/provisioner/consolidate")
      .method("put")
      .create()({
        provisionerName,
        optimize,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Single Analytics
export type GetSingleAnalyticsResponse = components["schemas"]["AnalyticsSingleAnalyticsResponse"];

export type GetSingleAnalyticsParams = {
  clusters?: string[];
  from?: number | null;
  to?: number | null;
  range?: string | null;
  multiCluster?: boolean;
  stepSize?: number | null;
  tags?: string[];
  type?:
    | "cpuRequests"
    | "cpuRequestsOrigin"
    | "cpuUsage"
    | "cpuRecommendation"
    | "cpuAllocatable"
    | "memoryRequests"
    | "memoryRequestsOrigin"
    | "memoryUsage"
    | "memoryRecommendation"
    | "memoryAllocatable"
    | "costOverTime"
    | "activeSavingsOverTime"
    | "activeSavingsOverTimeWithReplicas"
    | "automatedWorkloads"
    | "numberOfNodes"
    | "totalNumberOfWorkloads"
    | "totalNumberOfPods"
    | "numberOfAutomatedPods"
    | "totalAutomatedWorkloads"
    | "totalAutomatedCpu"
    | "totalAutomatedMemory"
    | "totalCpuUsed"
    | "totalMemoryUsed";
};

export const GetSingleAnalytics = () => {
  const queryKey = "getSingleAnalytics";
  const api = ScaleOps();
  const queryFn = async (params: GetSingleAnalyticsParams & WithTimeout) => {
    const { timeoutSeconds, ...queryParams } = params;
    const response = await api
      .getFetcher()
      .withTimeout(timeoutSeconds)
      .path("/api/v1/analytics/single")
      .method("get")
      .create()(queryParams)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Top K
export type GetTopKResponse = components["schemas"]["AnalyticsGetTopKResponse"];

export enum GetTopKQueryKey {
  Evictions = "evictions",
  EvictionsByNamespace = "evictionsByNamespace",
  ClusterEvictionsWorkloads = "podsEvictionsWorkloads",
  ClusterEvictionsNamespaces = "podsEvictionsNamespace",
  Liveness = "liveness",
  LivenessByNamespace = "livenessByNamespace",
  NodeCpuUtilization = "nodeCpuUtilization",
  NodeMemoryUtilization = "nodeMemoryUtilization",
  NodeCpuAllocationUsage = "nodeCpuAllocationUsage",
  NodeMemoryAllocationUsage = "nodeMemoryAllocationUsage",
  OOMOverTime = "oomOverTime",
  OOMOverTimeCauseLimits = "oomOverTimeCauseLimit",
  OOMOverTimeByNamespaceCauseLimit = "oomOverTimeByNamespaceCauseLimit",
  OOMOverTimeCauseNodePressure = "oomOverTimeCauseNodePressure",
  OOMOverTimeByNamespaceCauseNodePressure = "oomOverTimeByNamespaceCauseNodePressure",
  OOMOverTimeByNamespace = "oomOverTimeByNamespace",
  ResourceQuotaLimitedByNamespaceCPU = "resourceQuotaLimitedByNamespace-cpu",
  ResourceQuotaLimitedByNamespaceMemory = "resourceQuotaLimitedByNamespace-memory",
  ResourceQuotaLimitedByNamespacePods = "resourceQuotaLimitedByNamespace-pods",
  ResourceQuotaLimitedByNamespaceReplicaSets = "resourceQuotaLimitedByNamespace-replicaSets",
  ResourceQuotaObjects = "resourceQuotaObjects",
  Throttling = "throttling",
  ThrottlingByNamespace = "throttlingByNamespace",
  ExpensiveWorkloads = "expensiveWorkloads",
  ExpensiveNamespaces = "expensiveNamespaces",
  WastefulWorkloads = "wastefulWorkloads",
  WastefulNamespaces = "wastefulNamespaces",
  WastefulWorkloadsWithReplicas = "wastefulWorkloadsWithReplicas",
  WastefulNamespacesWithReplicas = "wastefulNamespacesWithReplicas",
  CpuUnderProvisionedWorkloadsStressedNode = "cpuUnderProvisionedWorkloads",
  CpuUnderProvisionedNamespacesStressedNode = "cpuUnderProvisionedNamespaces",
  MemoryUnderProvisionedWorkloadsStressedNode = "memoryUnderProvisionedWorkloads",
  MemoryUnderProvisionedNamespacesStressedNode = "memoryUnderProvisionedNamespaces",
  CpuUnderProvisioned = "cpuUnderProvisioned",
  MemoryUnderProvisioned = "memoryUnderProvisioned",
  CpuUnderProvisionedNamespace = "cpuUnderProvisionedNamespace",
  MemoryUnderProvisionedNamespace = "memoryUnderProvisionedNamespace",
  NodeNotScalingDown = "nodeNotScalingDown",
  NodeUsageType = "instanceUsageType",
  NodeInstanceType = "instanceTypes",
  WorkloadHasAutomated = "workloadHasAutomated",
  WorkloadHasAutomatedByNamespace = "workloadHasAutomatedByNamespace",
  WorkloadHasUnautomated = "workloadHasUnautomated",
  WorkloadHasUnUnautomatedByNamespace = "workloadHasUnautomatedByNamespace",
  WastedCpu = "wastedCpu",
  WastedMemory = "wastedMemory",
  WastedCpuByNamespace = "wastedCpuByNamespace",
  WastedMemoryByNamespace = "wastedMemoryByNamespace",
  UseSmartPolicyCpuDiff = "useSmartPolicyCpuDiff",
  UseSmartPolicyCpuDiffByNamespace = "useSmartPolicyCpuDiffByNamespace",
  UseSmartPolicyMemoryDiff = "useSmartPolicyMemoryDiff",
  UseSmartPolicyMemoryDiffByNamespace = "useSmartPolicyMemoryDiffByNamespace",
  DowntimeByWorkload = "downtimeByWorkload",
  DowntimeByNamespace = "downtimeByNamespace",
  DowntimeByWorkloadAuto = "downtimeByWorkloadAuto",
  DowntimeByNamespaceAuto = "downtimeByNamespaceAuto",
  ScaleOpsCPUUsage = "scaleOpsCPUUsage",
  ScaleOpsMemoryUsage = "scaleOpsMemoryUsage",
  ScaleOpsCPURequests = "scaleOpsCPURequests",
  ScaleOpsMemoryRequests = "scaleOpsMemoryRequests",
  IssuesOverTime = "issuesOverTime",
  Version = "version",
}

export type GetTopKParams = {
  annotationFilter?: string[];
  clusters?: string[];
  from?: number | null;
  labelsFilter?: string[];
  multiCluster?: boolean;
  namespacesFilter?: string[];
  quantile?: number | null;
  queryKey?: GetTopKQueryKey;
  range?: string | null;
  stepSize?: number | null;
  tags?: string[];
  to?: number | null;
  topK?: number;
  withSum?: boolean;
};

export const GetTopK = () => {
  const queryKey = "getSingleAnalytics";
  const api = ScaleOps();
  const queryFn = async (params: GetTopKParams & WithTimeout) => {
    const { timeoutSeconds, ...queryParams } = params;
    const response = await api
      .getFetcher()
      .withTimeout(timeoutSeconds)
      .path("/api/v1/analytics/topk")
      .method("get")
      .create()(queryParams)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

//#region Get Workload Analytics

export type GetWorkloadAnalyticsResponse = components["schemas"]["WorkloadsWorkloadAnalyticsResponse"];

export type WorkloadAnalyticsType =
  | "replicas"
  | "readyReplicas"
  | "minHpaReplicas"
  | "maxHpaReplicas"
  | "evictions"
  | "automated"
  | "oom"
  | "liveness"
  | "throttling"
  | "nodeCpuUtilization"
  | "nodeMemoryUtilization"
  | "hpaCpuThreshold"
  | "hpaMemoryThreshold"
  | "hpaCpuUsage"
  | "hpaMemoryUsage"
  | "hpaCpuThresholdFromUtilization"
  | "hpaMemoryThresholdFromUtilization"
  | "hpaCpuThresholdFromAverageValue"
  | "hpaMemoryThresholdFromAverageValue"
  | "cpuNoisyNeighbors"
  | "memoryNoisyNeighbors"
  | "cpuRequest"
  | "recommendedCpuRequest"
  | "memoryRequest"
  | "recommendedMemoryRequest"
  | "spotNodes"
  | "onDemandNodes"
  | "podCpuUsage"
  | "scaleDownReasons"
  | "podMemoryUsage"
  | "nodeCpuAllocatable"
  | "policyOverTime"
  | "nodeMemoryAllocatable"
  | "hpaTriggerThreshold"
  | "binPackedPods"
  | "totalPods"
  | "hpaTriggerValueReplicas";

export type GetWorkloadAnalyticsParams = {
  container?: string | null;
  from?: number | null;
  to?: number | null;
  name?: string;
  namespace?: string;
  workloadType?: string;
  isCustomOwnerType?: boolean;
  topk?: number;
  types?: WorkloadAnalyticsType[];
  uniqueQueryKey?: string;
};

export const GetWorkloadAnalytics = () => {
  const queryKey = "GetWorkloadAnalytics";
  const api = ScaleOps();
  const queryFn = async (parameters: GetWorkloadAnalyticsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/workload-analytics")
      .method("get")
      .create()(parameters)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};
//#endregion

//#region Get Node Analytics

export type NodeAnalyticsType =
  | "underProvisionedWorkloadsCpu"
  | "underProvisionedWorkloadsMemory"
  | "outOfMemory"
  | "autoHealingCpuStress"
  | "nodePressure"
  | "unevictablePods"
  | "podsCpuUsage"
  | "podsMemoryUsage"
  | "podsCpuRequest"
  | "podsMemoryRequest"
  | "scaleDownBlockReason"
  | "utilizationCpu"
  | "utilizationMemory";

export type GetNodeAnalyticsParams = {
  from?: number | null;
  to?: number | null;
  name?: string;
  topk?: number;
  types?: NodeAnalyticsType[];
};

export type GetNodeAnalyticsResponse = components["schemas"]["Node_analyticsNodeAnalyticsResponse"];

export const GetNodeAnalytics = () => {
  const queryKey = "GetNodeAnalytics";
  const api = ScaleOps();
  const queryFn = async (parameters: GetNodeAnalyticsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/node-analytics")
      .method("get")
      .create()(parameters)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

//#endregion

//#region Get Analytics Graph

export type AnalyticsGraphTypes = (
  | "activeSavingsOverTime"
  | "automatedWorkloadCountByPolicy"
  | "automatedWorkloads"
  | "availableSavingsOverTime"
  | "costOverTime"
  | "cpuAllocatable"
  | "cpuRecommendation"
  | "cpuRequests"
  | "cpuRequestsOrigin"
  | "cpuRequestsUnknownOwner"
  | "cpuUsage"
  | "cpuUsageUnknownOwner"
  | "hourlyAutomatedPods"
  | "memoryAllocatable"
  | "memoryRecommendation"
  | "memoryRequests"
  | "memoryRequestsOrigin"
  | "memoryRequestsUnknownOwner"
  | "memoryUsage"
  | "memoryUsageUnknownOwner"
  | "numberOfAutomatedPods"
  | "numberOfNodes"
  | "totalAutomatedCpu"
  | "totalAutomatedMemory"
  | "totalAutomatedWorkloads"
  | "totalCpuUsed"
  | "totalMemoryUsed"
  | "totalNumberOfPods"
  | "totalNumberOfWorkloads"
  | "unAutomatedWorkloadCountByPolicy"
  | "demoNodes"
  | "totalHpaAutomatedWorkloads"
  | "totalNumberOfWorkloadsWithHpa"
  | "totalAutomatedHpaCpuRequests"
  | "totalHpaCpuRequests"
  | "totalAutomatedHpaMemoryRequests"
  | "totalHpaMemoryRequests"
  | "cpuRequestsOriginWithReplicas"
  | "cpuRecommendationWithReplicas"
  | "memoryRequestsOriginWithReplicas"
  | "memoryRecommendationWithReplicas"
  | "availableSavingsOverTimeWithReplicas"
  | "activeSavingsOverTimeWithReplicas"
  | "blockedCpuAllocatable"
  | "blockedMemoryAllocatable"
  | "totalNumberOfUnevictablePodsByType"
  | "totalNumberOfOptimizedUnevictablePods"
)[];

export type GetAnalyticsGraphResponse = components["schemas"]["AnalyticsGraphAnalyticsResponse"];

export type GetAnalyticsGraphResponseParams = {
  from?: number;
  to?: number;
  range?: string | null;
  groupBy?: "hour" | "day" | "week";
  types?: AnalyticsGraphTypes;
  multiCluster?: boolean;
  tags?: string[];
};

export const GetAnalyticsGraph = () => {
  const queryKey = "getAnalyticsGraph";
  const api = ScaleOps();
  const queryFn = async (params: GetAnalyticsGraphResponseParams & WithTimeout) => {
    const { timeoutSeconds, ...queryParams } = params;
    const response = await api
      .getFetcher()
      .withTimeout(timeoutSeconds)
      .path("/api/v1/analytics/graph")
      .method("get")
      .create()(queryParams)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

//#endregion

// Get reports graph
export type GetReportsGraphResponse = components["schemas"]["ReportsGraphResponse"];

export type ProvisionedWRTOriginTypes = "cpu" | "memory" | "cpuAndMemory" | "cpuOrMemory";

export type GetReportsGraphProps = {
  annotations?: string[];
  clusters?: string[];
  labels?: string[];
  multiCluster?: boolean;
  namespaces?: string[];
  tags?: string[];
  timeout?: number;
  overProvisionedWRTOrigin?: "cpu" | "memory" | "cpuAndMemory" | "cpuOrMemory";
  underProvisionedWRTOrigin?: "cpu" | "memory" | "cpuAndMemory" | "cpuOrMemory";
  window?: string | null;
  scaleOpsProduct?: "all" | "HPA" | "rightsize";
};

export const GetReportsGraph = () => {
  const queryKey = "getReportsGraph";
  const api = ScaleOps();
  const queryFn = async (params: GetReportsGraphProps & WithTimeout) => {
    const { timeoutSeconds, ...queryParams } = params;
    const response = await api
      .getFetcher()
      .withTimeout(timeoutSeconds)
      .path("/api/v1/reports/graph")
      .method("get")
      .create()(queryParams)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get dashboard
export type GetDashboardResponse = components["schemas"]["DashGetClusterOverviewResponse"];

export type GetDashboardProps = {
  clusters?: string[];
  multiCluster?: boolean;
  tags?: string[];
  filterQueryParams?: FilterParamObject;
};

export const GetDashboard = () => {
  const queryKey = "GetDashboard";
  const api = ScaleOps();
  const queryFn = async (params: GetDashboardProps & WithTimeout) => {
    const { timeoutSeconds, ...queryParams } = params;
    const response = await api
      .getFetcher()
      .withTimeout(timeoutSeconds)
      .path("/api/v1/dashboard/")
      .method("get")
      .create()(queryParams)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Not Evictable
export type GetNotEvictableResponse = components["schemas"]["NodeGroupsGetNotEvictableDataResponse"];

export const GetNotEvictable = () => {
  const queryKey = "GetNotEvictable";
  const api = ScaleOps();
  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/not-evictable")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get HPAPolicies
export type GetHPAPoliciesResponse = components["schemas"]["Hpa_policiesHpaListResponse"];

export const GetHPAPolicies = () => {
  const queryKey = "GetHPAPolicies";
  const api = ScaleOps();
  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/hpaPolicies")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }

    if (MOCK_HPA_POLICIES_FETCHER) {
      return getHPAPoliciesMockData();
    }

    return response;
  };

  return { queryFn, queryKey };
};

// Delete HPA  Policy
export type DeleteHPAPolicyResponse = components["schemas"]["PoliciesDeleteResponse"];

export type DeleteHPAPolicyParams = {
  name: string;
};

export const DeleteHPAPolicy = () => {
  const api = ScaleOps();
  const queryKey = "DeleteHPAPolicy";

  const queryFn = async (params: DeleteHPAPolicyParams) => {
    if (MOCK_HPA_POLICIES_FETCHER) {
      const mockResponse = await deleteHPAPolicyMockData(params);
      return mockResponse;
    }

    const response = await api
      .getFetcher()
      .path("/api/v1/hpaPolicy/{name}")
      .method("delete")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Update HPA  Policy
export type UpdateHPAPolicyResponse = components["schemas"]["Hpa_policiesHpaUpdateResponse"];

export type UpdateHPAPolicyParams = components["schemas"]["HpaUpdateInput"] & { name: string };

export const UpdateHPAPolicy = () => {
  const api = ScaleOps();
  const queryKey = "UpdateHPAPolicy";

  const queryFn = async (params: UpdateHPAPolicyParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/hpaPolicy/{name}")
      .method("put")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Create HPA Policy
export type HPAPolicy = components["schemas"]["V1alpha1HpaPolicy"];
export type CreateHPAPolicyResponse = components["schemas"]["Hpa_policiesHpaListResponse"];

export type CreateHPAPolicyParams = components["schemas"]["HpaCreateInput"];

export const CreateHPAPolicy = () => {
  const api = ScaleOps();
  const queryKey = "CreateHPAPolicy";

  const queryFn = async (params: CreateHPAPolicyParams) => {
    if (MOCK_HPA_POLICIES_FETCHER) {
      const mockResponse = await createHPAPolicyMockData(params);
      return mockResponse;
    }

    const response = await api
      .getFetcher()
      .path("/api/v1/hpaPolicy/")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Not Evictable
export type GetNotEvictableFeatureFlagResponse =
  components["schemas"]["NodeGroupsGetNotEvictableDataResponseFeatureFlag"];

export const GetNotEvictableFeatureFlag = () => {
  const queryKey = "GetNotEvictableFeatureFlag";
  const api = ScaleOps();
  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodegroups/not-evictable-feature-flag")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Attach HPA Policy To Workload
export type AttachHPAPolicyResponse = components["schemas"]["UtilsAttachWorkloadResponse"];

export type AttachHPAPolicyParams = {
  name: string;
  isForce?: boolean;
  isInternal?: boolean;
  namespaceName?: string;
  target?: string;
};

export const AttachHPAPolicy = () => {
  const api = ScaleOps();
  const queryKey = "AttachHPAPolicy";

  const queryFn = async (params: AttachHPAPolicyParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/hpaPolicy/{name}/workload/attach")
      .method("patch")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Attach Policy To Workload:
export type AttachPolicyToWorkload =
  paths["/api/v1/policy/{name}/workload/attach"]["patch"]["responses"]["200"]["content"]["application/json"];

export type AttachPolicyToWorkloadParams = {
  name: string;
  target?: string | undefined;
  namespaceName?: string | undefined;
  oldPolicyName?: string | undefined;
  id: string;
};

export const attachPolicyToWorkload = () => {
  const queryKey = "attachPolicyToWorkload";
  const api = ScaleOps();

  const queryFn = async ({ name, target, namespaceName }: AttachPolicyToWorkloadParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/policy/{name}/workload/attach")
      .method("patch")
      .create()({
        name,
        target,
        namespaceName,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Workload Annotations
export type GetWorkloadAnnotationsResponse = components["schemas"]["WorkloadsGetWorkloadAnnotationsResponse"];

export type GetWorkloadAnnotationsParams = {
  name: string;
  namespace: string;
  kind: string;
};

export const GetWorkloadAnnotations = () => {
  const queryKey = "GetWorkloadAnnotation";
  const api = ScaleOps();
  const queryFn = async (params: GetWorkloadAnnotationsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/workload/{namespace}/{kind}/{name}/annotations")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Workload Yaml
export type GetWorkloadYamlResponse = components["schemas"]["WorkloadsGetWorkloadYamlResponse"];

export type GetWorkloadYamlParams = {
  name: string;
  namespace: string;
  kind: string;
};

export const GetWorkloadYaml = () => {
  const queryKey = "GetWorkloadYaml";
  const api = ScaleOps();
  const queryFn = async (params: GetWorkloadYamlParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/workload/{namespace}/{kind}/{name}/yaml")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Workload Labels
export type GetWorkloadLabelsResponse = components["schemas"]["WorkloadsGetWorkloadLabelsResponse"];

export type GetWorkloadLabelsParams = {
  name: string;
  namespace: string;
  kind: string;
};

export const GetWorkloadLabels = () => {
  const queryKey = "GetWorkloadLabels";
  const api = ScaleOps();
  const queryFn = async (params: GetWorkloadAnnotationsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/workload/{namespace}/{kind}/{name}/labels")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get config
export type GetConfigResponse = components["schemas"]["ConfGetConfResponse"];

export const getConfig = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const currentClusterURLParam = urlParams.get("currentClusterURLParam");

  const withNoCluster = currentClusterURLParam ? false : true;

  const queryKey = "config";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher(withNoCluster)
      .path("/conf")
      .method("get")
      .create()({})
      .then((response) => {
        if (response?.headers?.get("X-Scaleops-Server") === "snapshot") {
          sessionStorage.setItem("snapshot-server", "true");
        }
        return response.data;
      });

    if (response instanceof ApiError) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Clusters
export type GetConfLoginResponse = components["schemas"]["ConfGetLoginConfResponse"];
export const getConfLogin = (): {
  queryFn: () => Promise<GetConfLoginResponse>;
  queryKey: string;
} => {
  const queryKey = "getConfLogin";
  const api = ScaleOps();
  const queryFn = async (): Promise<GetConfLoginResponse> => {
    const response = await api
      .getFetcher()
      .path("/conf/login")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof ApiError) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get SimpleAuth
export type GetSimpleAuthResponse = components["schemas"]["Simple_authGetSimpleAuthResponse"];

export const GetSimpleAuth = () => {
  const queryKey = "GetSimpleAuth";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/simple-auth")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Get Is Read Only
export type GetIsReadOnlyResponse = components["schemas"]["DevGetUIReadOnlyResponse"];

export const GetIsReadOnly = () => {
  const queryKey = "GetIsReadOnly";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/dev/ui-read-only")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type GetNodeDrainingEnabledResponse = components["schemas"]["SettingsGetNodeDrainingEnabledResponse"];

export const GetNodeDrainingEnabled = () => {
  const queryKey = "GetNodeDrainingEnabled";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/settings/node-draining")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

export const StopNodeRebalance = () => {
  const queryKey = "StopNodeRebalance";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodes/stop-rebalance")
      .method("post")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type UpdateMultiAlertsSettingsInput = components["schemas"]["SetMulticlusterAlertsSettingsInput"] & {
  multiCluster?: boolean;
  clusters?: string[];
  tags?: string[];
};
export const UpdateMultiAlertsSettings = () => {
  const queryKey = "UpdateMultiAlertsSettings";
  const api = ScaleOps();

  const queryFn = async (params: UpdateMultiAlertsSettingsInput) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/alerts/multicluster/settings")
      .method("post")
      .create()({
        ...params,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type UpdateSlackConfMultiInput = components["schemas"]["SetMulticlusterSlackSettingsInput"] & {
  multiCluster?: boolean;
  clusters?: string[];
  tags?: string[];
};

export const UpdateSlackConfMulti = () => {
  const queryKey = "UpdateSlackConf";
  const api = ScaleOps();
  const queryFn = async (params: UpdateSlackConfMultiInput) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/alerts/slack/multicluster/settings")
      .method("post")
      .create()({
        ...params,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type MultiClusterQueryParams = {
  clusters?: string[];
  multiCluster?: boolean;
  tags?: string[];
};

export type TriggerSlackAlertTestParams = components["schemas"]["TestSlackAlertInput"] & MultiClusterQueryParams;

export const TriggerSlackAlertTest = () => {
  const queryKey = "TriggerSlackAlertTest";
  const api = ScaleOps();
  const queryFn = async (params: TriggerSlackAlertTestParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/alerts/slack/test")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type GetUIFeaturesResponse = components["schemas"]["SettingsGetUIFeaturesResponse"];

export const GetUIFeaturesQuery = () => {
  const queryKey = "GetUIFeatures";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/settings/ui-features")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };
  return { queryFn, queryKey };
};

export type GetBetaFeaturesResponse = components["schemas"]["SettingsGetBetaFeaturesResponse"];

export const GetBetaFeaturesQuery = () => {
  const queryKey = "GetBetaFeatures";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/settings/beta-features")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };
  return { queryFn, queryKey };
};

export type VerifySlackTokenResponse = components["schemas"]["SlackVerifyTokenResponse"];
export type VerifySlackTokenInput = {
  token: string;
};

export type TokenVerificationError = {
  Type: string;
  Message: string;
};

export const VerifySlackToken = () => {
  const queryKey = "VerifySlackToken";
  const api = ScaleOps();
  const queryFn = async (params: VerifySlackTokenInput) => {
    const response: components["schemas"]["SlackVerifyTokenResponse"] = await api
      .getFetcher()
      .path("/api/v1/slack/verify/token")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type VerifySlackChannelResponse = components["schemas"]["SlackVerifyChannelResponse"];
export type VerifySlackChannelInput = {
  token: string;
  channel: string;
};

export const VerifySlackChannel = () => {
  const queryKey = "VerifySlackChannel";
  const api = ScaleOps();
  const queryFn = async (params: VerifySlackChannelInput) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/slack/verify/channel")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type SlackEnabledMultiClusterResponse = components["schemas"]["AlertsSlackEnabledMultiClusterResponse"];

export const SlackEnabledMultiCluster = () => {
  const queryKey = "SlackEnabledMultiCluster";
  const api = ScaleOps();
  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/alerts/slack/multicluster/settings/slackenabled")
      .method("get")
      .create()({
        multiCluster: true,
      } as MultiClusterQueryParams)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type GetIngressesResponse = components["schemas"]["SettingsGetIngressesResponse"];

export const GetExistingIngress = () => {
  const queryKey = "GetExistingIngress";
  const api = ScaleOps();
  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/settings/ingresses")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };
  return { queryFn, queryKey };
};

// Create Ingress
export type CreateIngressInput = components["schemas"]["CreateIngressesInput"];

export const CreateIngress = () => {
  const api = ScaleOps();
  const queryKey = "CreateIngress";

  const queryFn = async (params: CreateIngressInput) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/settings/ingresses")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

// Delete Ingress
export const DeleteIngress = () => {
  const api = ScaleOps();
  const queryKey = "DeleteIngress";

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/settings/ingresses")
      .method("delete")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type GetAdmissionOptionsResponse = components["schemas"]["AdmissionGetAdmissionSettingsResponse"];
export const GetAdmissionOptions = () => {
  const api = ScaleOps();
  const queryKey = "GetAdmissionOptions";

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/admission/settings")
      .method("get")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type SetAdmissionOptionsParams = components["schemas"]["SetAdmissionSettingsInput"];

export const SetAdmissionOptions = () => {
  const api = ScaleOps();
  const queryKey = "SetAdmissionOptions";

  const queryFn = async (params: SetAdmissionOptionsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/admission/settings")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw response;
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type UpdatePolicyByClusterParams = {
  policyName: string;
};

export type UpdatePolicyByClusterResponse = components["schemas"]["UtilsAttachPolicyClusterResponse"];

export const UpdatePolicyByCluster = () => {
  const api = ScaleOps();
  const queryKey = "UpdatePolicyByCluster";

  const queryFn = async (params: UpdatePolicyByClusterParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/policy/{name}/cluster/attach")
      .method("patch")
      .create()({
        name: params.policyName,
      })
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type RestoreClusterPolicyResponse = components["schemas"]["UtilsClusterRestorePolicyResponse"];

export const RestoreClusterPolicy = () => {
  const api = ScaleOps();
  const queryKey = "RestoreClusterPolicy";

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/policy/restore-policy/cluster")
      .method("post")
      .create()({})
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type UpdateBetaFeaturesParams = components["schemas"]["SettingsGetBetaFeaturesResponse"];
export type UpdateBetaFeaturesResponse = components["schemas"]["SettingsSetBetaFeaturesResponse"];

export const UpdateBetaFeatures = () => {
  const api = ScaleOps();
  const queryKey = "UpdateBetaFeatures";
  const queryFn = async (params: UpdateBetaFeaturesParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/settings/beta-features")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };

  return { queryFn, queryKey };
};

export type RestoreNamespacesPolicyParams = components["schemas"]["RestoreNamespacesPolicyInput"];
export type RestoreNamespacesPolicyResponse = components["schemas"]["UtilsNamespaceRestorePolicyResponse"];

export const RestoreNamespacesPolicy = () => {
  const api = ScaleOps();
  const queryKey = "RestoreNamespacesPolicy";

  const queryFn = async (params: RestoreNamespacesPolicyParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/policy/restore-policy/namespaces")
      .method("post")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };
  return { queryFn, queryKey };
};

export type UpdateCustomNamespaceLabelsParams = components["schemas"]["SettingsGetCustomNamespaceLabelsResponse"];
export const UpdateCustomNamespaceLabels = () => {
  const queryKey = "UpdateCustomNamespaceLabels";
  const api = ScaleOps();

  const queryFn = async (params: UpdateCustomNamespaceLabelsParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/settings/custom-namespace-labels")
      .method("put")
      .create()(params)
      .then((response) => response.data);
    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type UpdateIgnoredNamespacesParams = components["schemas"]["SetUserIgnoredNamespacesInput"];
export type UpdateIgnoredNamespacesResponse = components["schemas"]["SettingsUserIgnoredNamespacesResponse"];

export const UpdateIgnoredNamespaces = () => {
  const queryKey = "UpdateIgnoredNamespaces";
  const api = ScaleOps();

  const queryFn = async (params: UpdateIgnoredNamespacesParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/settings/user-ignored-namespaces")
      .method("put")
      .create()(params)
      .then((response) => response.data);
    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};

export type GetIgnoredNamespacesResponse = components["schemas"]["SettingsUserIgnoredNamespacesResponse"];

export const GetIgnoredNamespaces = () => {
  const queryKey = "GetIgnoredNamespaces";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/settings/user-ignored-namespaces")
      .method("get")
      .create()({})
      .then((response) => response.data);
    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };

  return { queryFn, queryKey };
};

export type GetCustomNamespaceLabelsResponse = components["schemas"]["SettingsGetCustomNamespaceLabelsResponse"];

export const GetCustomNamespaceLabels = () => {
  const queryKey = "GetCustomNamespaceLabels";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/settings/custom-namespace-labels")
      .method("get")
      .create()({})
      .then((response) => response.data);
    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };

  return { queryFn, queryKey };
};

export type GetActiveIssuesResponse = components["schemas"]["HealthGetActiveIssuesResponse"];

export const GetActiveIssues = () => {
  const queryKey = "GetActiveIssues";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/active-issues/")
      .method("get")
      .create()({})
      .then((response) => response?.data);
    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };

  return { queryFn, queryKey };
};

//#region Unevictable Queries

//#region GetUnevictableData
export type GetUnevictableQueryParams = {
  from?: number;
  to?: number;
};

export type GetUnevictableDataResponse = components["schemas"]["UnevictableGetUnevictableDataResponse"];

export const GetUnevictableData = () => {
  const queryKey = "GetUnevictableData";

  const api = ScaleOps();

  const queryFn = async (params: GetUnevictableQueryParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/unevictable/")
      .method("get")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };

  return { queryFn, queryKey };
};

//#endregion

//#region GetUnevictableGraph

export type GetUnevictableGraphParams = {
  from?: number | null;
  range?: string | null;
  stepSize?: number | null;
  to?: number | null;
};

export type GetUnevictableGraphResponse = components["schemas"]["UnevictableGetUnevictableGraphResponse"];

export const GetUnevictableGraph = () => {
  const queryKey = "GetUnevictableGraph";
  const api = ScaleOps();

  const queryFn = async (params: GetUnevictableGraphParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/unevictable/graph")
      .method("get")
      .create()(params)
      .then((response) => response.data);
    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };

  return { queryFn, queryKey };
};

//#endregion

//#region SetUnevictableAuto
export type SetUnevictableAutoResponse = components["schemas"]["UnevictableSetUnevictableAutoResponse"];
export type SetUnevictableAutoParams = components["schemas"]["SetUnevictableAutoInput"];

export const SetUnevictableAuto = () => {
  const api = ScaleOps();
  const queryKey = "SetUnevictableAuto";

  const queryFn = async (params: SetUnevictableAutoParams) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/unevictable/auto")
      .method("patch")
      .create()(params)
      .then((response) => response.data);

    if (response instanceof Error) {
      throw new Error(response.message);
    }
    return response;
  };

  return { queryFn, queryKey };
};
//#endregion

//#endregion

export type GetAvailableSavingsResponse = components["schemas"]["HealthGetAvailableSavingsResponse"];

export const GetAvailableSavings = () => {
  const queryKey = "GetAvailableSavings";
  const api = ScaleOps();

  const queryFn = async () => {
    const response = await api
      .getFetcher()
      .path("/api/v1/available-savings/")
      .method("get")
      .create()({})
      .then((response) => response?.data);
    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };

  return { queryFn, queryKey };
};

export type GetNodeCaLogsResponse = components["schemas"]["NodesNodeCaLogsResponse"];

export const GetNodeCaLogs = () => {
  const queryKey = "GetNodeCaLogs";
  const api = ScaleOps();

  const queryFn = async (nodeName: string) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodes/{name}/node-ca-logs")
      .method("get")
      .create()({ name: nodeName })
      .then((response) => response?.data);
    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };

  return { queryFn, queryKey };
};

export type GetIsCaLogsSupportedResponse = components["schemas"]["NodesIsCaLogsSupportedResponse"];

export const GetIsCaLogsSupported = () => {
  const queryKey = "GetIsCaLogsSupported";
  const api = ScaleOps();

  const queryFn = async (nodeName: string) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodes/{name}/node-ca-logs/is-ca-supported")
      .method("get")
      .create()({ name: nodeName })
      .then((response) => response?.data);
    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };

  return { queryFn, queryKey };
};

export type GetNodeEventsResponse = components["schemas"]["NodesGetNodeEventsResponse"];

export const GetNodeEvents = () => {
  const queryKey = "GetNodeEvents";
  const api = ScaleOps();

  const queryFn = async (name: string) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodes/{name}/events")
      .method("get")
      .create()({ name })
      .then((response) => response?.data);
    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };

  return { queryFn, queryKey };
};

export type GetEventsResponse = components["schemas"]["EventsGetEventsResponse"];

export const GetEvents = () => {
  const queryKey = "GetEvents";
  const api = ScaleOps();

  const queryFn = async (namespaceName: string, recommendationName: string) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/events/byNamespace/{namespaceName}/byRecommendation/{recommendationName}")
      .method("get")
      .create()({ namespaceName, recommendationName })
      .then((response) => response?.data);
    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };

  return { queryFn, queryKey };
};

export type GetNodeYamlResponse = components["schemas"]["NodesGetNodeYamlResponse"];

export const GetNodeYaml = () => {
  const queryKey = "GetNodeYaml";
  const api = ScaleOps();

  const queryFn = async (name: string) => {
    const response = await api
      .getFetcher()
      .path("/api/v1/nodes/{name}/yaml")
      .method("get")
      .create()({ name })
      .then((response) => response?.data);
    if (response instanceof Error) {
      throw new Error(response.message);
    }

    return response;
  };

  return { queryFn, queryKey };
};
